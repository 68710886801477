

export const ENSITE = {
    site: {
        titulo:"Subsidiary Management",
        editar:"Edit subsidiary",
        activos:"Subsidiary assets",
        mis:"My subsidiarys",
        ubicacion:"Sensor location",

        error:"Error retrieving subsidiaryes",
        errorCrear:"Error creating the subsidiary",

        placeHolderNombre:"Subsidiary name",
        placeHolderDescripcion:"Subsidiary description",
        placeHolderDireccion:"Subsidiary address",

        mensajeActualizacion:"Subsidiary updated successfully",

        errorActualizacion:"Error updating the subsidiary",
        sinDatos:"No subsidiarys available"

      }
}

export const ESSITE = {
    site: {
        titulo:"Gestión de sucursales",
        editar:"Editar sucursal",
        activos:"Activos de la sucursal",
        mis:"Mis sucursales",
        ubicacion:"Ubicación del sensor",

        error:"Error al obtener las sucursales",
        errorCrear:"Error al crear la sucursal",

        placeHolderNombre:"Nombre de la sucursal",
        placeHolderDescripcion:"Descripción de la sucursal",
        placeHolderDireccion:"Dirección de la sucursal",

        mensajeActualizacion:"Sucursal actualizada correctamente",

        errorActualizacion:"Error al actualizar la sucursal",
        sinDatos:"No hay sucursales"

      }
}