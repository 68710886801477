import { FieldType } from "../Components/CreateReseller/CreateResellerComponent";
import { Asset } from "../Models/Asset";
import { DataGraph } from "../Models/DataGraph";
import { energyClassification } from "../Models/EnergyClassification";
import { Historical } from "../Models/Historical";
import { NotificationContact } from "../Models/NotificationContact";
import { Reseller } from "../Models/Reseller";
import { ResponseContent } from "../Models/ResponseContent";
import { Sensor } from "../Models/Sensor";
import { Task } from "../Models/Task";
import { User } from "../Models/User";
import { postApi, getApi, patchApi, deleteApi } from "./Api";


const generateKey = async <T>(objectArray: Promise<Sensor[]>): Promise<Sensor[]> => {
    // Código de la función
    let response = await objectArray;
    for (let index = 0; index < response.length; index++) {
        response[index].key = (index+1).toString()
    }
    return response;
  };

export const postAsset = async (createAssetDTO:FieldType) => {
    try{
        return await postApi<ResponseContent>('thermotrack/subsidiary/addAsset', createAssetDTO)

    }
    catch(error){
        throw error;
    }
}

export const postNotifications = async (object:any) => {
    try{
        return await postApi<ResponseContent>('thermotrack/subsidiary/addReceiversEmails', object)

    }
    catch(error){
        throw error;
    }
}

export const postNotificationsPhone = async (object:any) => {
    try{
        return await postApi<ResponseContent>('thermotrack/subsidiary/addReceiversPhones', object)

    }
    catch(error){
        throw error;
    }
}

export const postTask = async (objeto: any) => {
    try{
        return await postApi<ResponseContent>('thermotrack/subsidiary/addTask', objeto)

    }
    catch(error){
        throw error;
    }
}

export const postNewAsignSensor = async (idAsset:string, idSensor:string, objeto: any) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/subsidiary/${idAsset}/asset/${idSensor}/assignSensor`, objeto)
    }
    catch(error){
        throw error;
    }
}

export const asignSensorToTask = async (id:string, objeto: any) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/subsidiary/${id}/task/assignSensor`, objeto)
    }
    catch(error){
        throw error;
    }
}

export const asignSensorToAsset = async (id:string, objeto: any) => {
    try{
        return await patchApi<ResponseContent>(`thermotrack/subsidiary/sensor/assignSensorToAsset/${id}`, objeto)
    }
    catch(error){
        throw error;
    }
}

export const deleteSensorToAsset = async (id:string, serial:string) => {
    try{
        return await patchApi<ResponseContent>(`thermotrack/subsidiary/sensor/${serial}/asset/${id}/unassignSensorFromAsset`, {})
    }
    catch(error){
        throw error;
    }
}

export const stopTask = async (id:string) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/subsidiary/${id}/stopTask`, {})
    }
    catch(error){
        throw error;
    }
}

export const getAssets = async () => {
    try{
        return await getApi<Asset[]>('thermotrack/subsidiary/assets')
    }
    catch(error){
        throw error;
    }
}

export const getNotifications = async () => {
    try{
        return await getApi<NotificationContact[]>('thermotrack/subsidiary/getReceivers')
    }
    catch(error){
        throw error;
    }
}

export const getHistorical = async (id:string) => {
    try{
        return await getApi<Historical[]>(`thermotrack/subsidiary/asset/${id}/historical`)
    }
    catch(error){
        throw error;
    }
}

export const getUsers= async () => {
    try{
        return await getApi<User[]>('thermotrack/subsidiary/users')
    }
    catch(error){
        throw error;
    }
}

export const getAvailableTaskSensors = async () => {
    try{
        return await getApi<Sensor[]>('thermotrack/subsidiary/sensors/unassigned')
    }
    catch(error){
        throw error;
    }
}

export const getAssetDetail = async (id:string) => {
    try{
        return await getApi<Asset>(`thermotrack/subsidiary/${id}/assetsDetail`)
    }
    catch(error){
        throw error;
    }
}

export const getTasks = async () => {
    try{
        return await getApi<Task[]>('thermotrack/subsidiary/tasks')
    }
    catch(error){
        throw error;
    }
}

export const getSensors = async () => {
    try{
        return await generateKey( getApi<Sensor[]>('thermotrack/subsidiary/sensors'))
    }
    catch(error){
        throw error;
    }
}

export const getAvailableSensors = async () => {
    try{
        let sensors = await getApi<Sensor[]>('thermotrack/subsidiary/sensors/task/unassigned')
        for (let index = 0; index < sensors.length; index++) {
            sensors[index].key = (index+1).toString()
            
        }
        return await sensors
    }
    catch(error){
        throw error;
    }
}

export const getAlertsByAsset = async (asssetId:number) => {
    try{
        return await getApi<any[]>(`thermotrack/subsidiary/assetId/${asssetId}/tasks/alarms`)


    }
    catch(error){
        throw error;
    }
}

export const getUpdateLocation = async () => {
    try{
        return await getApi<ResponseContent>(`idsense/sensor/sensors/validateLocation`)
    }
    catch(error){
        throw error;
    }
}

export const getEnergyClassification = async () => {
    try{
        return await getApi<energyClassification[]>('thermotrack/subsidiary/energyClassification')
    }
    catch(error){
        throw error;
    }
}

export const postAddUser = async (obj: object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/subsidiary/addUsers`, obj)
    }
    catch(error){
        throw error;
    }
}

export const getDataGraph = async (id:number) => {
    try{
        return await getApi<ResponseContent>(`thermotrack/sensor/temps/${id}`)
    }
    catch(error){
        throw error;
    }
}

export const getAssetsSensorDetail= async (id:number | string, num?:number) => {
    try{
        return await getApi<any[]>(`thermotrack/subsidiary/assets/${id}/sensors?days=${num}`)
    }
    catch(error){
        throw error;
    }
}

export const getAssetsSensorDetailByNumber= async (id:number | string) => {
    try{
        return await getApi<any[]>('thermotrack/subsidiary/assets/'+id+'/sensors')
    }
    catch(error){
        throw error;
    }
}


export const getDataReport= async (id:number | string) => {
    try{
        return await getApi<any>('thermotrack/subsidiary/sensor/'+id+'/report')
    }
    catch(error){
        throw error;
    }
}

export const getDataReportByData= async (id:number | string, from:string , to:string ) => {
    try{
        return await getApi<any>(`thermotrack/subsidiary/sensor/${id}/reportByDate?startDate=${from}&endDate=${to}`)
    }
    catch(error){
        throw error;
    }
}

export const getLocationByDate= async (id:number | string, from:string , to:string ) => {
    try{
        return await getApi<any>(`thermotrack/subsidiary/sensor/location?taskId=${id}&startDate=${from}&endDate=${to}`)
    }
    catch(error){
        throw error;
    }
}

export const getLocationAsset= async (idAsset:number | string, idTask:number | string) => {
    try{
        return await getApi<any>(`thermotrack/subsidiary/assetId/${idAsset}/location?taskId=${idTask}`)
    }
    catch(error){
        throw error;
    }
}

export const patchSensor= async (idSensor:string, body:object) => {
    try{
        return await patchApi<any>(`thermotrack/subsidiary/${idSensor}/updateSensor`, body)
    }
    catch(error){
        throw error;
    }
}

export const patchAsset= async (idAsset:number, body:object) => {
    try{
        return await patchApi<any>(`thermotrack/subsidiary/${idAsset}/updateAsset`, body)
    }
    catch(error){
        throw error;
    }
}

export const deleteNotificationContact= async (id:number) => {
    try{
        return await deleteApi<any>(`thermotrack/subsidiary/subsidiary/receiver/${id}/deleteReceiver`)
    }
    catch(error){
        throw error;
    }
}

export const deleteAsset= async (id:number) => {
    try{
        return await patchApi<any>(`thermotrack/subsidiary/${id}/deleteAsset`, {})
    }
    catch(error){
        throw error;
    }
}