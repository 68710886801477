import React, { useContext, useEffect, useState } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { Card, Segmented } from 'antd';
import Spiner from '../../Components/Spiner/Spiner';
import NoData from '../../Components/NoData/NoData';
import SubsidiaryGraph from '../../Components/SubsidiaryGraph/SubsidiaryGraph';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetDetail, getAssetsSensorDetail, getLocationAsset } from '../../Api/Subsidiary';

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import './AssetDetail.css'
import AssetDetailCard from '../../Components/AssetDetailCard/AssetDetailCard';
import SingleCardInformation from '../../Components/SingleCardInformation/SingleCardInformation';
import { Asset } from '../../Models/Asset';
import { Sensor } from '../../Models/Sensor';
import TableDetailAsset from '../../Components/TableSensor/TableDetailAsset';
import { MenuItem } from '../../Models/MenuItem';
import FilterSimple from '../../Components/FilterSection/FilterSimple';

import { getAvailableTaskSensors, asignSensorToAsset } from '../../Api/Subsidiary'
import AssignSensorComponent from '../../Components/AssignSensor/AssignSensorComponent';
import { FieldType } from '../../Components/CreateTask/CreateTaskComponent';
import { getAssetsDetailSensors, getAssetsDetailViewData, getLocationAssetOrganization } from '../../Api/Organization';
import DoubleCardInformation from '../../Components/SingleCardInformation/DoubleCardInformation';
import ModalAlertAsset from '../../Components/AlertAsset/ModalAlertAsset';
import { dateToString, stringToDate } from '../../Functions/date';
import ModalButton from '../../Components/ModalButton/ModalButton';
import { BsFillPinMapFill } from 'react-icons/bs';
import { TagOutlined } from '@ant-design/icons';
import MapApp from '../../Components/MapApp/MapApp';
import { generateTempFormat } from '../../Functions/temp';



const AssetDetail = () => {
  const { t } = useTranslation();
  const [dataGraph, setDataGraph] = useState<any[]>([]);
  const [renderDataGraph, setRenderDataGraph] = useState<any[]>([]);

  const [data, setData] = useState<Asset>();
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [location, setLocation] = useState<{lat: number, lng: number} | null>();

  const [id, setID] = useState<string>("");
  const [serialSelected, setSerialSelected] = useState<string>("");

  const [tabAlarm, setTabAlarm] = useState<string>("1")
  const [tabDay, setTabDay] = useState<string>("Diario")



  
  const [alarmCount, setAlarmCount] = useState<number>(0);
  const [assetStatus, setAssetStatus] = useState<string>("");

  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const navigate = useNavigate();


  const [sensorLastTemperature, setSensorLastTemperature] = useState<{temperature:number|null, date:string|null}>({temperature:null, date:null});
  const [startedDate, setStartedDate] = useState<string>('');

  const [sensorInformation, setSensorInformation] = useState<{top:number| string, topSubText:string, bottom:number| string, bottomSubText:string, media:string|number}>({top:0, topSubText:"", bottom:0, bottomSubText:"", media:0});
  const { error, sendError, sesion } = useContext(PromiseSesionContext);
  const params = useParams();


  const [loadingS, setLoadingS] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingA, setLoadingA] = useState(true);


  const [menuItem, setMenuItem] = useState<MenuItem>();


  const items=[
    {
      title: t('basico.seleccionarSensor'),
    },{
      title: t('basico.configuracion'),
    },{
      title: t('basico.detalle'),
    }
  ]

  // const menuItem:MenuItem ={
  //   label: <span>Crear</span>,
  //   key: '0',
  //   title: 'Crear Cliente',
  //   icon: <></>,
  //   component: <AssignSensorComponent prevInitialValues={prevInitialValues} asignSensorToTask={asignSensorToAsset} getAvailableSensors={getAvailableTaskSensors} single idTask={asset.id.toString()} />,
  //   type: 'MULTICONTENT',
  //   items: items,
  //   size: 'small',
  //   onConfirm: () => {},
  // }

  const changeSelectedSerial = (serial:string)=>{
    setSerialSelected(serial)
  }

  const filterGraph = () =>{
    let dataSensor = data?.tarea.filter(x=> x.sensor.serial_number=== serialSelected)[0]
    let newRegister = dataGraph.filter((x)=> x.sensorSerial==serialSelected)
    let dataRegister= newRegister[0]?.sensorData
    console.log("Tarea ", data?.tarea[0], dataRegister)
    setSensorLastTemperature({temperature:data?.tarea[0]?.sensor.unverified.last_temperature || 0, date:data?.tarea[0]?.sensor.unverified.last_date_time || ""})
    setRenderDataGraph(newRegister)
    if(dataSensor) setStartedDate(dataSensor.started || "")
  }

  useEffect(() => {
    console.log("enter data", renderDataGraph)
    }, [renderDataGraph]);

    useEffect(() => {
      console.log("enter data", renderDataGraph)
      findSensorsByAsset(params.id || 0)

      }, [tabDay]);

  useEffect(() => {
    if(dataGraph.length>0 && serialSelected!="" && data){
      filterGraph()
    }
    }, [serialSelected, data, dataGraph]);

  const generateMenuItem = (detailAsset:Asset)=>{
    let prevInitialValues:FieldType ={temp:[0, 20]}
    let blocked = false
    if(detailAsset.tarea.length>0){
      prevInitialValues ={
        temp:[0, 20],
        alarm_high_temp: detailAsset.tarea[0].taskDetails.alarm_high_temp,
        alarm_low_temp: detailAsset.tarea[0].taskDetails.alarm_low_temp,
        descripcion : detailAsset?.descripcion,
        name: detailAsset?.nombre,
        high_duration_minutes: detailAsset.tarea[0].taskDetails.high_duration_minutes,
        interval_minutes:detailAsset.tarea[0].taskDetails.interval_minutes,
        low_duration_minutes: detailAsset.tarea[0].taskDetails.low_duration_minutes,

      }
      blocked =true
    }

    setMenuItem({
      label: <span>Crear</span>,
      key: '0',
      title: 'Crear Cliente',
      icon: <></>,
      component: <AssignSensorComponent blockElements={blocked}  prevInitialValues={prevInitialValues} asignSensorToTask={asignSensorToAsset} getAvailableSensors={getAvailableTaskSensors} single idTask={params.id?.toString() || "0"} />,
      type: 'MULTICONTENT',
      items: items,
      size: 'small',
      onConfirm: () => {getDataInit()},
    })


  }


  const generateCards = async (newAsset:Asset) =>{


    let task = newAsset.tarea
    let alarms = 0
    let status = "Activos"
    for(const t of task) {
      alarms= alarms + (t.alarm_count>0 ? 1 : 0)

      if(t.status == "TASK_STATUS_START_PENDING"){
        status="Por activar"
      }

      if(t.status == "TASK_STATUS_ACTIVE_WITH_ALARM"){
        status="Hay alarmas"
      }
    }

    setAssetStatus(status)
    setAlarmCount(alarms)
  }

  const flipDataSensor =  (data:any[])=>{

    let newData = []

    for (let index = 0; index < data.length; index++) {
      let element = data[index];

      // element.sensorData = element.sensorData.slice(0,500).reverse()
      element.sensorData = element.sensorData.reverse()

      newData.push(element)
      
    }

    return newData

  }

  const findSensorsByAsset = async (id: string | number) => {
    try{

      setLoadingS(true)
      let days = tabDay=="Diario"? 0 : tabDay=="Semanal" ? 7 : 30
      const res = sesion.context.rol==="Sucursal" ? await getAssetsSensorDetail(id,days) : await getAssetsDetailSensors(params.idSucursal || 0, params.idAsset || 0,days)
      console.log("Data sensor", res)
      setDataGraph(flipDataSensor(res))
      if(res.length>0){
        changeSelectedSerial(res[0].sensorSerial)
      }
      setLoadingS(false)
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})
        setLoadingS(false)
  
      }
   }

   const createSensors = (elementAsset: Asset) => {
    let tasks = elementAsset.tarea
    let resultSensors = []
    for (let t of tasks){
      resultSensors.push(t.sensor)
    }


    return resultSensors

   }

   const addLocation = async (idTarea:string)=>{
    try{
      console.log("aquiiiiii ", params.idAsset)
      const res = sesion.context.rol==="Sucursal" ? await getLocationAsset(params.id|| 0, idTarea|| 0) : await getLocationAssetOrganization(params.idAsset || 0, idTarea|| 0,params.idSucursal || 0 )
      setLocation({lat: res.latitud, lng: res.longitud})

    }
    catch(e:any){
      sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})

    }
    // if(!sensor.latitud && !sensor.longitud) return

    // setLocation({lat: sensor.latitud, lng: sensor.longitud})
   }

   const getAssetData = async () =>{
    try{
      setLoadingA(true)
      setLoading(true)
      
      const res = sesion.context.rol==="Sucursal" ? await getAssetDetail(params.id || "") : await getAssetsDetailViewData(params.idSucursal || 0, params.idAsset || 0)
      generateMenuItem(res)
      setSensors(createSensors(res))
      setData(res)
      setLoading(false)
      console.log("Aquiiiii ", res.tarea)
      if(res.tarea.length>0) addLocation(res.tarea[0].id)
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})
        setLoading(false)
  
      }
   }

   const getDataInit = ()=>{
    getAssetData()
    //findSensorsByAsset(params.id || 0)
   }

   useEffect(() => {
    getDataInit()
    }, []);

    useEffect(() => {
      if(data){
        setLoadingA(false)
        generateCards(data)
        // setID(data.asset_tarea_id)
      }
      }, [data]);

    let styleCard:React.CSSProperties ={
      width: "32%",
      
    }

  const generateInformation = ()=>{
    if(dataGraph.length == 0) return

    let max: number| null= null
    let min: number | null= null

    let dateMax= ""
    let dateMin= ""


    let sum: number = 0

    for(let d of dataGraph){
      if(d.sensorData.length!==0 ){ 
      const element = d.sensorData
      let newMax = Math.max(...element.map((x:any)=> x.temperature))



      if(!max || newMax > max ) {
        max= newMax
        dateMax= element.filter((x:any)=> x.temperature ==newMax)[0].timestamp

        let date = new Date(dateMax)
        dateMax = dateToString(date)
      }

      let newMin = Math.min(...element.map((x:any)=> x.temperature))
      if(!min ||newMin <= min){
        min= newMin
        dateMin= element.filter((x:any)=> x.temperature ==newMin)[0].timestamp
        let date = new Date(dateMin)
        dateMin = dateToString(date)
      }

      let elementSum: number = element.map((x:any)=> x.temperature).reduce(function(a:any, b:any)
      {
        return a + b;
      });
      sum += (elementSum / element.length)
    }
      
    }


    setSensorInformation({top:(max || 0), topSubText:dateMax, bottom:(min ||0), bottomSubText:dateMin, media:(sum/dataGraph.length).toFixed(2)})

  }

  useEffect(() => {
    if(dataGraph.length>0){
      generateInformation()

    }
    }, [dataGraph]);

    const onChangeAlarm = (key: string) => {
      setTabAlarm(key)
    };

    let menuItemLocation:MenuItem =   {
      label: <span>Ubicacion del sensor</span>,
      title: t('site.ubicacion'),
      key: '1',
      icon: <TagOutlined />,
      component: <div style={{width:"100%", height:"300px"}}> <MapApp sites={[]} location={location} findAction={()=>{}} /></div>,
      type: 'CONTENT',
      items: [],
      size: 'small',
      onConfirm: () => {},

    }

  return (
    <div>
      <Segmented
      
      value={tabDay}
    options={['Diario', 'Semanal']}
    disabled={loadingS}
    
    onChange={(value) => {
      setTabDay(value.toString())
      console.log(value); // string
    }}
  />
        <div className='AssetDetail-top'>
          <Card title={data?.nombre||t('basico.titulo')} className='AssetDetail-top-card'  bodyStyle={{padding:0, width:"100%"}} extra={location?.lat && <ModalButton menuItem={menuItemLocation} text={""}  icon={<BsFillPinMapFill />}></ModalButton>}  >
          {/* <Card title={undefined} style={{width:"35%"}}  bodyStyle={{padding:0, height:"100%"}}  > */}

            <Spiner loading={loading} >
              {data&& <AssetDetailCard 
              titulo={data?.nombre||t('basico.titulo')}
              high={data?.tarea[0]?.taskDetails?.alarm_high_temp}
              low={data?.tarea[0]?.taskDetails?.alarm_low_temp}
              interval={data.tarea[0]?.taskDetails?.interval_minutes ||0}
              linterval={data.tarea[0]?.taskDetails?.low_duration_minutes||0}
              interval_sec={data.tarea[0]?.taskDetails?.interval_seconds||0}
              hinterval={data.tarea[0]?.taskDetails?.high_duration_minutes||0}
              detalle={data?.descripcion|| ""}
              />}

            </Spiner>
          </Card>

          <div className='AssetDetail-CardsSection'>
            <DoubleCardInformation loading={loadingS} elementClass='Card-dasset-cards' title={t('activo.temperaturaAlta')} value={(sensorInformation.top)+ " C°"} subValue={sensorInformation.topSubText} />
            <DoubleCardInformation loading={loadingS} elementClass='Card-dasset-cards' title={t('activo.temperaturaBaja')} value={sensorInformation.bottom + " C°"} subValue={sensorInformation.bottomSubText} />
            <DoubleCardInformation loading={loadingS} elementClass='Card-dasset-cards' title={t('activo.ultimaLectura')} value={generateTempFormat(sensorLastTemperature.temperature || 0)} subValue={sensorLastTemperature.date ?stringToDate(sensorLastTemperature.date || " ") : ""} />

            <SingleCardInformation loading={loadingS} elementClass='Card-dasset-cards' title={t('activo.mediaTemperatura')} value={sensorInformation.media + " C°"} />
            <SingleCardInformation loading={loading} elementClass='Card-dasset-cards' title={t('basico.estatus')} value={assetStatus || 0} />
            <SingleCardInformation loading={loading} elementClass='Card-dasset-cards' title={t('basico.fechaInicio')} value={startedDate!=="" ? stringToDate(startedDate) : "-" } haveDetails action={()=>{setOpenAlert(true)}} />


          
          </div>
        </div>

        <div className='AssetDetail-bottom'>
          <Card title={t('basico.sensores')} className='AssetDetail-bottom-lcard'  bodyStyle={{padding:"12px"}} extra={(menuItem&& !loading)&& (sesion.context.rol==="Sucursal") && sesion.context.user.rol==="admin" ? <FilterSimple menuItem={menuItem} title={t('basico.asignarSensor')} /> : <></>} >
            <Spiner loading={loading} >
              <TableDetailAsset selectedSerial={serialSelected} sensors={sensors} refresh={getDataInit} selectSensor={changeSelectedSerial} ></TableDetailAsset>
            </Spiner>
          </Card>

          <Card title={t('basico.grafico')} className='AssetDetail-bottom-rcard' style={{height:"calc(65vh - 3rem)"}}  bodyStyle={{padding:"12px", height:"84%"}}  >
            <Spiner loading={loadingS} >
              <NoData data={renderDataGraph} text={t('sensor.sinDatos')}>
                <SubsidiaryGraph enterData={renderDataGraph} maxRef={data?.tarea[0]?.taskDetails?.alarm_high_temp}
              minRef={data?.tarea[0]?.taskDetails?.alarm_low_temp} />
              </NoData>
            </Spiner>
          </Card>
        </div>

        <ModalAlertAsset id={Number(params.id) || 0} open={openAlert} setOpen={setOpenAlert} />

    </div>
  )
}

export default AssetDetail