import React, { useContext, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import ElementCard from './ElementCard'
import { MenuItem } from '../../Models/MenuItem'
import { DeleteOutlined, EditOutlined, PlusOutlined, TagOutlined } from '@ant-design/icons'
import { Asset } from '../../Models/Asset';
import DeleteSensorByTask from '../DeleteSensorByTask/DeleteSensorByTask';
import AssignSensorComponent from '../AssignSensor/AssignSensorComponent';

import { getAvailableTaskSensors, asignSensorToAsset } from '../../Api/Subsidiary'
import { FieldType } from '../CreateTask/CreateTaskComponent';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { BsBook, BsGraphDown, BsSliders } from 'react-icons/bs';
import UpdateAsset from '../UpdateAsset/UpdateAsset';

//transalate
import { useTranslation, Trans } from 'react-i18next';
import RemoveAsset from '../RemoveAsset/RemoveAsset';



interface Props{
    asset: Asset, 
    propsStyles?: React.CSSProperties 
    action?: (id: string | number) => void
    notShowMenu?: boolean
    simpleCard?: boolean
    refresh?: () => void,
    showAlarms?:boolean
    elementClass?:string
}

const ElementCardAsset = ({asset, propsStyles, action, notShowMenu , simpleCard, refresh, showAlarms, elementClass}:Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { sesion } = useContext(PromiseSesionContext);
    const params = useParams();

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [alarms, setAlarms] = useState<number>(0);


    const countAlarms = () =>{
      let count = 0

      for(let t of asset.tarea){
        count = count + (t.alarm_count > 0 ? 1 : 0)
      }
      setAlarms(count)

    }

    useEffect(() => {
      let newMenuItems:MenuItem[] = []
      
      if(showAlarms){
        countAlarms()
      }

      if(asset.sensor_numero_de_serie && !notShowMenu && sesion.context.user.rol==="admin")
      newMenuItems.push(
        {
          label: <span>Quitar Sensor</span>,
          title: t('basico.quitarSensor'),
          key: '1',
          icon: <DeleteOutlined />,
          component: <DeleteSensorByTask id={asset.id ? asset.id.toString() : asset.asset_id.toString()} serial={asset.sensor_numero_de_serie} />,
          type: 'CONTENT',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        })

        if(!asset.sensor_numero_de_serie && !notShowMenu)
        {
          let prevInitialValues:FieldType ={temp:[0, 20]}

          if(asset.tarea.length>0){
            prevInitialValues ={
              temp:[0, 20],
              alarm_high_temp: asset.tarea[0].taskDetails.alarm_high_temp,
              alarm_low_temp: asset.tarea[0].taskDetails.alarm_low_temp,
              descripcion : "",
              name:asset.tarea[0].nombre,
              high_duration_minutes: asset.tarea[0].taskDetails.high_duration_minutes,
              interval_minutes:asset.tarea[0].taskDetails.interval_minutes,
              low_duration_minutes: asset.tarea[0].taskDetails.low_duration_minutes,

            }
          }
          if(sesion.context.user.rol==="admin")
          {
          newMenuItems.push(
          {
            label: <span>Asignar Sensor</span>,
            title: 'Asignar sensor 1',
            key: '1',
            icon: <PlusOutlined />,
            component: <AssignSensorComponent prevInitialValues={prevInitialValues} asignSensorToTask={asignSensorToAsset} getAvailableSensors={getAvailableTaskSensors} single idTask={asset.id.toString()} />,
            type: 'MULTICONTENT',
            items: [{
              title: t('basico.seleccionarSensor'),
            },{
              title: t('basico.configuracion'),
            },{
              title: t('basico.detalle'),
            }],
            size: 'small',
            onConfirm: () => { refresh!()},
      
          })}}

          
      
      setMenuItems(newMenuItems)

    }, [asset, showAlarms]);

    const render=()=>{

      let newMenuItems:MenuItem[] = []

      // let status = (asset.tarea_status ? taskStatus(asset.most_recent.sensor_task_status) : undefined)
      // let colorStatus = (asset.tarea_status ? taskColors(asset.most_recent.sensor_task_status) : undefined)
      //sensors={asset.tarea.length} 
      if(simpleCard) return <ElementCard elementClass={elementClass} alarms={alarms} showAlarms={showAlarms} sensors={asset.tarea ? asset.tarea.length : 0}  onClick={action}  id={asset.id || asset.asset_id} propsStyles={propsStyles}  menuItems={notShowMenu ? undefined :newMenuItems} title={asset.nombre || asset.asset_nombre} description={asset.descripcion || asset.asset_descripcion} image={asset.tipo=="Nevera" ? '/image/nevera.png' : '/image/canastilla.png'} status={""} dontShowStatus/>

      newMenuItems.push(
        {
          label: <span>{t('basico.detalle')}</span>,
          title: t('basico.detalle'),
          key: '2',
          icon: <BsGraphDown />,
          component: <></>,
          type: 'REDIRECT',
          items: [],
          size: 'small',
          onConfirm: () => { sesion.context.rol==="Sucursal" ? navigate("/Asset/"+asset.id.toString()) : navigate("/Asset/"+asset.id.toString()+'/'+ (params.idSucursal || "") )},
    
        },
        {
          label: <span>{t('basico.historico')}</span>,
          title: t('basico.historico'),
          key: '4',
          icon: <BsBook />,
          component: <></>,
          type: 'REDIRECT',
          items: [],
          size: 'small',
          onConfirm: () => { sesion.context.rol==="Sucursal" ? navigate("/Historic/"+asset.id.toString()) : navigate("/Historic/"+asset.id.toString()+'/'+ (params.idSucursal || ""))},
    
        },
        )



      if(asset.sensor_numero_de_serie && !notShowMenu && sesion.context.user.rol==="admin"){

      newMenuItems.push(
        {
          label: <span>{t('basico.quitarSensor')}</span>,
          title: t('basico.quitarSensor'),
          key: '1',
          icon: <DeleteOutlined />,
          component: <DeleteSensorByTask id={asset.id.toString()} serial={asset.sensor_numero_de_serie} />,
          type: 'CONTENT',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        })}

        // if(!asset.sensor_numero_de_serie)

        let prevInitialValues:FieldType ={temp:[0, 20]}
        let blockElements = false
        let task

        if(asset.tarea.length>0) task= asset?.tarea[0]?.status || "false"
        if(asset.tarea.length>0 && !notShowMenu){
          blockElements = true
          prevInitialValues ={
            temp:[asset.tarea[0].taskDetails.alarm_low_temp, asset.tarea[0].taskDetails.alarm_high_temp],
            alarm_high_temp: asset.tarea[0].taskDetails.alarm_high_temp,
            alarm_low_temp: asset.tarea[0].taskDetails.alarm_low_temp,
            descripcion : asset.descripcion,
            name:asset.tarea[0].nombre,
            high_duration_minutes: asset.tarea[0].taskDetails.high_duration_minutes,
            interval_minutes:asset.tarea[0].taskDetails.interval_minutes,
            low_duration_minutes: asset.tarea[0].taskDetails.low_duration_minutes,

          }
        }
        
        if(sesion.context.user.rol==="admin"){
          newMenuItems.push(

            {
              label: <span>{t('activo.editar')}</span>,
              title: t('activo.editar'),
              key: '5',
              icon: <BsSliders />,
              component: <UpdateAsset asset={asset} idSite={Number(params.idSucursal)} />,
              type: 'CONTENT',
              items: [],
              size: 'small',
              onConfirm: () => {refresh!()},
        
            },
  
            )
        
        newMenuItems.push(
          {
            label: <span>{t('basico.asignarSensor')}</span>,
            title: t('basico.asignarSensor'),
            key: '3',
            icon: <PlusOutlined />,
            component: <AssignSensorComponent blockElements={blockElements} prevInitialValues={prevInitialValues} asignSensorToTask={asignSensorToAsset} getAvailableSensors={getAvailableTaskSensors} single idTask={asset.id.toString()} />,
            type: 'MULTILABEL',
            items: [{
              title: t('basico.seleccionarSensor'),
            },{
              title: t('basico.configuracion'),
            },{
              title: t('basico.detalle'),
            }],
            size: 'small',
            onConfirm: () => {refresh!()},

            
      
          },
          {
            label: <span>{t('activo.eliminar')}</span>,
            title: t('activo.eliminar'),
            key: '1',
            icon: <DeleteOutlined />,
            component: <RemoveAsset id={asset.id} canDelete={task !== "TASK_STATUS_START_PENDING"}/>,
            type: 'CONTENT',
            items: [],
            size: 'small',
            onConfirm: () => {refresh!()},
      
          }
        
        )}
          
          return <ElementCard elementClass={elementClass} alarms={alarms} showAlarms={showAlarms} id={asset.asset_id} sensors={asset.tarea.length}  propsStyles={propsStyles}  menuItems={notShowMenu ? undefined :newMenuItems} title={asset.nombre || asset.asset_nombre} description={asset.descripcion || asset.asset_descripcion} image={asset.tipo=="Nevera" ? '/image/nevera.png' : '/image/canastilla.png'} status={""} dontShowStatus/>
    }
      
  return (
    <>
      {/* <ElementCard  id={asset.id} propsStyles={propsStyles} onClick={action ? action : undefined} menuItems={notShowMenu ? undefined :menuItems} title={asset.nombre || asset.asset_nombre} description={asset.descripcion || asset.asset_descripcion} image='' status='' dontShowStatus/> */}
      {render()}
    </>
  )
}

export default ElementCardAsset